<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />
    <div class="content_margin_bottom">
      <div class="row"></div>
      <section>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div v-if="!startTest">
                  <!-- <section>
                    <h2>Before you start</h2>
                  </section> -->
                  <div class="test_condition">
                    <h2 style="font-weight: 800; font-size: 18px;">
                      {{ test.unit_name }}
                    </h2>
                  </div>
                  <div class="test_condition">
                    <p>
                      <i class="fas fa-list"></i>
                    </p>
                    <p>
                      <span style="font-weight: 800; font-size: 18px;">{{
                        test.questions && test.questions.length
                      }}</span>
                      multiple choice questions
                    </p>
                  </div>
                  <div class="test_condition">
                    <p><i class="far fa-calendar-check"></i></p>
                    <p>
                      Pass score is
                      <span style="font-weight: 800; font-size: 18px;">{{
                        test.pass_score
                      }}</span>
                    </p>
                  </div>
                  <div class="test_condition">
                    <p><i class="fas fa-business-time"></i></p>
                    <p>
                      Maximum number of attempts is
                      <span style="font-weight: 800; font-size: 18px;">{{
                        test.max_num_attempts
                      }}</span>
                    </p>
                  </div>
                  <div class="test_condition">
                    <p><i class="far fa-clock"></i></p>
                    <p>
                      <span style="font-weight: 900; font-size: 18px;"
                        >{{ test.duration_in_secs }} seconds
                      </span>
                      per question
                    </p>
                  </div>
                  <div class="row justify-content-end">
                    <div class="py-2 py-3">
                      <router-link
                        :to="
                          `/user/${id}/enroll_course/${courseId}/details/${testId}`
                        "
                        class="btn btn-danger px-4 mr-2"
                        >Decline</router-link
                      >
                      <button
                        @click="addInputs(test.questions)"
                        class="btn btn-primary btn-block my-btn float-right"
                      >
                        Start
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="!showResults">
                    <div>
                      <div class="row justify-content-between">
                        <div class="col-md-12 py-2 py-3 border-bottom">
                          <h2
                            style="font-weight: 800; font-size: 18px; text-align: center"
                          >
                            {{ test.unit_name }} Skills Assessment
                          </h2>
                        </div>
                      </div>

                      <div>
                        <div class="p-4">
                          <p style="float:right">
                            Q{{ index + 1 }}/{{ test.questions.length }}
                          </p>
                          <p style="font-size:18px;">
                            {{ test.questions[index].ques }}
                          </p>
                        </div>
                        <div class="divide_section">
                          <div
                            v-for="(answer, j) in test.questions[index].answers"
                            :key="j"
                            class="d-flex p-3"
                          >
                            <div
                              class=" mb-3 w-75 pl-2"
                              style="cursor:pointer"
                              @click="
                                selectResponse(answer, test.questions[index])
                              "
                            >
                              <label
                                :class="[
                                  answeredQuestions[index] &&
                                  answeredQuestions[index].answer_id ==
                                    answer.id
                                    ? 'border-primary'
                                    : '',
                                ]"
                                style="width:calc(100% - 22px);height:100%!important;"
                                :for="`question${index}${j}`"
                                class="form-group pt-2 pl-4 border"
                              >
                                <!-- <label  class="ml-4"> -->
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  readonly
                                  :id="`question${index}${j}`"
                                  v-model="test.questions[index].answer"
                                  :checked="isSelected"
                                  :value="j"
                                />
                                {{ answer.choice }}
                                <!-- </label> -->
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {{ totalScores }}
                    <div class="col-md-12 d-flex justify-content-between">
                      <div v-if="!showResults" class=" py-3 ">
                        <strong>Time: {{ countDown }}</strong>
                        {{ scores }}
                      </div>
                      <div class="py-2 py-3">
                        <button
                          v-if="!showResults"
                          @click.prevent="nextQuestion"
                          :disabled="!isSelected"
                          class="btn btn-primary btn-block my-btn float-right"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="showResults">
                    <div
                      style="
    width: 50%;
    margin: 0 auto;
    text-align: center;
"
                    >
                      <div v-if="totalScores >= test.pass_score">
                        <p>
                          <i
                            class="fas fa-check-circle"
                            style="font-size: 60px; color: green"
                          ></i>
                        </p>
                        <h3>Congratulations, you passed the test.</h3>
                      </div>
                      <div v-else>
                        <p>
                          <i
                            class="fas fa-times-circle"
                            style="font-size: 60px; color: red"
                          ></i>
                        </p>
                        <h3>Sorry, You failed the test.</h3>
                      </div>
                      <h4>
                        Your Test Score is: {{ totalScores }} out of
                        {{ overAllTotalScore }}
                      </h4>
                      <h4>Pass Score: {{ test.pass_score }}</h4>
                      <button
                        v-if="totalScores >= test.pass_score"
                        @click="completedCourse"
                        :disabled="test.test_status == 'passed'"
                        class="btn btn-success btn-sm my-5"
                      >
                        Completed Course
                      </button>
                      <router-link
                        v-if="totalScores < test.pass_score"
                        :to="
                          `/user/${id}/enroll_course/${courseId}/details`
                        "
                        class="btn btn-danger"
                      >
                        OK
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import store from "@/state/store";
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import toasterMixin from "@/mixins/toasterMixin.js";

export default {
  page: {
    title: "Content",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    PageHeader,
    Layout,
  },
  computed: {
    totalScores() {
      let total = 0;
      if (this.scores.length == 0) {
        total = 0;
      } else {
        this.scores.forEach((score) => (total += score));
      }

      return total;
    },
  },
  data() {
    return {
      title: "Test",
      items: [
        {
          text: "User",
        },
        {
          text: "Test",
          active: true,
        },
      ],
      newInput: [],
      isSelected: false,
      startTest: false,
      requestError: false,
      testId: this.$route.params.testId,
      id: this.$route.params.id,
      courseId: this.$route.params.courseId,
      enrollmentId: this.$route.params.enrollmentId,
      error: null,
      success: null,
      loading: false,
      test: [],
      answeredQuestions: [],
      index: 0,
      results: 0,
      score: 0,
      scores: [],
      showResults: false,
      countDown: 0,
      timer: null,
      overAllTotalScore: 0,
    };
  },
  methods: {
    completedCourse() {
      store
        .dispatch("enroll/doneCourse", this.testId)
        .then((response) => {
          console.log(response, "done course");
          this.toast({ message: "Course Completed", type: "success" });
          this.$router.push(
            `/user/${this.id}/enroll_course/${this.courseId}/details`
          );
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });
    },
    resultsHandler() {
      let total = 0;

      this.test.questions.forEach((que) => (total += que.weight));

      this.overAllTotalScore = total;

      this.showResults = true;
      this.addQuestion();
    },
    nextQuestion() {
      if (this.test.questions.length - 1 === this.index) {
        return this.resultsHandler();
      }
      clearTimeout(this.timer);
      if (this.countDown < 0) {
        this.index++;
        this.results++;
        this.countDown = this.test.duration_in_secs;
        this.countDownTimer();
      } else {
        this.index++;
        this.results++;
        this.countDown = this.test.duration_in_secs;
        this.countDownTimer();
      }
    },

    countDownTimer() {
      if (this.countDown > 0) {
        this.timer = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.nextQuestion();
      }
    },
    selectResponse(answer, question) {
      let scoreKeyExist = this.scores[this.index];
      if (answer.correct == true) {
        if (typeof scoreKeyExist == "undefined") {
          this.scores.push(question.weight);
        } else {
          this.scores.splice(this.index, 1);
          this.scores.push(question.weight);
        }
      } else {
        if (typeof scoreKeyExist !== "undefined") {
          this.scores.splice(this.index, 1);
          this.scores.push(0);
        } else {
          this.scores.push(0);
        }
      }
      let test = {
        question_id: question.id,
        answer_id: answer.id,
      };
      let keyExist = this.answeredQuestions[this.index];
      if (typeof keyExist == "undefined") {
        this.answeredQuestions.push(test);
      } else {
        this.answeredQuestions.splice(this.index, 1);
        this.answeredQuestions.push(test);
      }

      this.isSelected = true;
    },

    addInputs(res) {
      this.startTest = true;
      let questions = res.map((question) => {
        const getAnswers = question.answers;
        const answers = getAnswers.map((answer) => {
          let choices = {
            choice: answer.choice,
            correct: "False",
          };

          return choices;
        });
        let exactQuestion = {
          ques: question.ques,
          weight: question.weight,
          answers: answers,
        };
        return exactQuestion;
      });

      this.newInput.push(questions);
      this.countDown = this.test.duration_in_secs;
      this.countDownTimer();
    },

    addQuestion() {
      this.loading = true;
      this.error = null;
      this.success = null;

      const question = {
        enrollment_id: parseInt(this.testId),
        test_id: this.test.id,
        test_answer: this.answeredQuestions,
      };
      const data = {
        test: question,
        test_id: this.test.id,
      };
      console.log(question);

      // DISPATCHING DATA TO THE BACKEND

      store
        .dispatch("contents/submitTest", data)
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data);
            this.loading = false;
            // this.toast({ message: "Test Submitted!", type: "success" });
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          if (err?.response?.data == undefined) {
            this.loading = false;
            this.toast({
              message:
                "Network error. Check your network connection and try again.",
              type: "error",
            });
          }
        });
    },
    fetchData() {
      this.loading = true;
      store
        .dispatch("contents/viewContent", this.enrollmentId)
        .then((res) => {
          const test = res.data;

          console.log(test, "content");
          this.test = test;
        })
        .catch((err) => {
          if (err?.response?.data === undefined)
            this.requestError = "An error occured";
        });
    },
  },

  created() {
    this.fetchData();
  },
};
</script>
<style scoped>
label {
  color: #000;
}
@media (min-width: 768px) {
  .divide_section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.removeItem {
  color: red;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 20px;
  width: 50% !important;
  justify-content: center;
}
.removeItem > i {
  font-size: 20px;
}
#multi {
  width: 100%;
}
#multi > div {
  margin: 0 20px 20px 20px;
  width: 100%;
}
.table-wrapper {
  background: #fff;
  padding: 20px 25px;
  margin: 30px 0;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.table-title {
  padding-bottom: 15px;
  background: linear-gradient(40deg, #ff2020, #05ffa3);
  color: #fff;
  padding: 16px 30px;
  margin: -20px -25px 10px;
  border-radius: 1px 1px 0 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.table-title h2 {
  margin: 5px 0 0;
  font-size: 24px;
}
.my-btn {
  width: 150px;
}
fieldset {
  width: inherit;
  padding: 0 10px;
  border-bottom: none;
}

.test_condition {
  margin: 20px 0 0 50px;
  display: flex;
}

#inliner {
  width: 15px;
  height: 20px;
}
.test_condition p:first-child {
  margin-right: 10px;
  font-size: 20px;
}

.test_condition > p {
  font-size: 16px;
}

#resluts {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}
</style>
